/* Utility variables are similar to primitives.css, but we permit them to be used globally
 * without being mapped to a contextual system variable name. */
:root {
  --color-black-a5: rgba(30 34 38 / 0.05);
  --color-black-a20: rgba(30 34 38 / 0.2);
  --color-black-a40: rgba(30 34 38 / 0.4);
  --color-black-a60: rgba(30 34 38 / 0.6);
  --color-black-a80: rgba(30 34 38 / 0.8);

  --color-white-a5: rgba(255 255 255 / 0.05);
  --color-white-a20: rgba(255 255 255 / 0.2);
  --color-white-a40: rgba(255 255 255 / 0.4);
  --color-white-a60: rgba(255 255 255 / 0.6);
  --color-white-a80: rgba(255 255 255 / 0.8);

  --radius-xs: 4px;
  --radius-sm: 8px;
  --radius-md: 12px;
  --radius-lg: 16px;
  --radius-xl: 999px;
}
