.mantine-Alert-root {
  &[data-variant='success'] {
    color: var(--color-successDark);
    background-color: var(--color-successLight);
    border-color: var(--color-successDark);
  }

  &[data-variant='warning'] {
    color: var(--color-warningDark);
    background-color: var(--color-warningLight);
    border-color: var(--color-warningDark);
  }

  &[data-variant='error'] {
    color: var(--color-errorDark);
    background-color: var(--color-errorLight);
    border-color: var(--color-errorDark);
  }

  &[data-variant='info'] {
    color: var(--color-infoDark);
    background-color: var(--color-infoLight);
    border-color: var(--color-infoDark);
  }
}

/* Override default Alert styles to inherit font color from
   the root element. */
.mantine-Alert-title {
  margin-bottom: 0;
  color: inherit;
  font-size: 100%;
  /* Ensure that heading elements within conform to size */
  & h3,
  & h2 {
    font-size: 100%;
    margin: 0;
  }
}

.mantine-Alert-message {
  color: inherit;
}
