/* stylelint-disable csstools/value-no-unknown-custom-properties --
   This file overrides a Mantine component, and therefore is allowed
   to reference CSS primitives directly.
*/

.mantine-Chip-root {
  &[data-variant='tag'] {
    .mantine-Chip-label {
      background-color: transparent;
      border: 1.5px solid var(--color-textSecondary);
      padding: 0 20px;
      color: var(--color-textSecondary);

      &[data-checked] {
        background-color: var(--color-brandPrimary);
        color: var(--color-white-1);
      }
    }

    .mantine-Chip-iconWrapper {
      display: none;
    }
  }
}
