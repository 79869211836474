/* stylelint-disable csstools/value-no-unknown-custom-properties --
   This file is declaring system variables, and therefore is allowed to use CSS primitives.
*/

/* Product branding overrides. */

/* N.B. In future these variables may be set dynamically based on Tenant Configuration
 * loaded from the backend. */

/**
 * Default
 */
:root {
  --color-brandAccent: var(--mantine-color-blue-6);
  --color-brandPrimary: var(--mantine-color-blue-8);
  --color-brandPrimary-active: var(--mantine-color-blue-9);
  --color-brandPrimary-dark: var(--mantine-color-blue-7);
  --color-brandPrimary-mid: var(--mantine-color-blue-4);
  --color-brandPrimary-light: var(--mantine-color-blue-2);
  --color-brandPrimary-subtle: var(--mantine-color-blue-1);
  --color-brandReference: var(--mantine-color-blue-8);
  --color-background-dark: var(--mantine-color-gray-0);
  --color-background-white: var(--color-white-0);

  --color-header-background: var(--color-background-white);
  --color-header-foreground: var(--mantine-color-gray-7);

  --image-logo-url: url('/images/demo/caire-icon-blue.svg');
  /* Values are unitless in config, allow them to be used in CSS calc() operations */
  --image-logo-width: 24;
  --image-logo-height: 26;

  /* This ratio can be used to size the logo image based on another fixed dimension */
  --image-logo-ratio: calc(var(--image-logo-height) / var(--image-logo-width));
}

/**
 * Caire
 */
.theme-caire {
  --color-brandAccent: #00b4aa;
  --color-brandPrimary: #085b9e;
  --color-brandPrimary-active: #054880;
  --color-brandPrimary-dark: #0a6dbf;
  --color-brandPrimary-mid: #82c1f5;
  --color-brandPrimary-light: #d2e8fa;
  --color-brandPrimary-subtle: #ebf4fc;
  --color-brandReference: #0e406a;
  --color-background-dark: var(--mantine-color-gray-0);
  --color-background-white: var(--color-white-0);
}

/**
 * Upliv Brand

 * Commented out to make it easier to transition to this new brand system.
 * Restore once Northwell Direct tenant is fully stood up.
.theme-caire-upliv {
  --color-brandAccent: #fa8552;
  --color-brandPrimary: var(--mantine-color-pink-9);
  --color-brandPrimary-active: #7a235c;
  --color-brandPrimary-dark: var(--mantine-color-pink-8);
  --color-brandPrimary-mid: var(--mantine-color-pink-4);
  --color-brandPrimary-light: var(--mantine-color-pink-2);
  --color-brandPrimary-subtle: var(--mantine-color-pink-1);
  --color-brandReference: #752157;
  --color-background-dark: var(--mantine-color-gray-0);
  --color-background-white: var(--color-white-0);

  --color-header-background: var(--color-brandPrimary);
  --color-header-foreground: var(--color-white-0);

  --image-logo-url: url('/images/upliv/logo-on-dark.svg');
  --image-logo-width: 84;
  --image-logo-height: 37;
}
  */

.theme-northwell-direct {
  --color-brandAccent: #009adf;
  --color-brandPrimary: #0079bf;
  --color-brandPrimary-active: #003ca5;
  --color-brandPrimary-dark: #0781e5;
  --color-brandPrimary-mid: #75c3fa;
  --color-brandPrimary-light: #cceaff;
  --color-brandPrimary-subtle: #e8f5ff;
  --color-brandReference: #003ca5;

  --color-background-dark: var(--mantine-color-gray-0);
  --color-background-white: var(--color-white-0);

  --color-header-background: var(--color-background-white);
  --color-header-foreground: var(--mantine-color-gray-7);

  --image-logo-url: url('/images/nwd/nwd-logo.svg');
  --image-logo-width: 118;
  --image-logo-height: 30;
}
