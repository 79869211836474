.mantine-Table-table {
  /* override default Mantine borderCollapse for borderSpacing so that
     borderRadius still works. */
  border-collapse: initial;
  border-spacing: 0;
  border-radius: 10px;
  background-color: var(--color-fill-light);
  /* Add overflow: hidden to ensure row highlight doesn't bleed
    out around borderRadius corners. */
  overflow: hidden;

  &[data-with-table-border='true'] {
    border: 1px solid var(--color-border);
  }

  &[data-variant='rightAlignLastColumn'] {
    /* For UI tables where the final column might be an action button,
       this variant right-aligns the final column. */
    .mantine-Table-th:last-child:not(:only-child),
    .mantine-Table-td:last-child:not(:only-child) {
      text-align: right;
    }

    & td:last-child:not(:only-child) {
      & > button {
        justify-self: flex-end;
      }
    }
  }
}

.mantine-Table-th {
  color: var(--color-textSecondary);
  font-size: 14px;
  font-weight: 400;
  height: 40px;

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
  }
}

.mantine-Table-tr {
  height: 60px;

  &[data-with-row-border='true'] {
    .mantine-Table-th,
    .mantine-Table-td {
      border-bottom: 1px solid var(--color-border);
    }

    &:last-child .mantine-Table-td {
      border-bottom: 0;
    }
  }
}

.mantine-Table-td {
  padding: 10px;

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
  }
}

.mantine-Table-tr .mantine-Table-th[data-with-column-border='true'],
.mantine-Table-td[data-with-column-border='true'] {
  border-right: 1px solid var(--color-border);

  &:last-child {
    border-right: 0;
  }
}
