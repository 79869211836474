.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
}

.header {
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 16px;
  max-width: 320px;
}

.title {
  color: var(--color-textPrimary);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.224px;
}

.description {
  color: var(--color-textSecondary);
  line-height: 24px;
  letter-spacing: 0.179px;
}
