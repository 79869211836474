/** Global CSS shared by all applications, applies element resets to
  * encourage semantic HTML use in UI, while not requiring incessant
  * undoing of padding and margin. */

* {
  box-sizing: border-box;
}

html {
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  line-height: 1.55;
  background-color: var(--color-background-dark);

  @media print {
    background-color: transparent;
  }
}

body {
  margin: 0;
  padding: 0;
}

/* By default, headings 1-3 are bold. We remove default margins and padding. */
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: inherit;
}

h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: inherit;
  font-size: inherit;
}

/* Remove default margin from <p> to ensure easy semantic use in UI */
p {
  margin: 0;
  padding: 0;
}

/* Default links */
a:link {
  text-decoration: none;
}

a:focus-visible,
a:hover,
a:active {
  text-decoration: underline;
}

/*
 * Reset lists to have no formatting, since they are commonly used for
 * accessible structure throughout the application.
 *
 * Components that use lists for typography should apply their own styles.
 */
ul,
ol,
li {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

/*
 stylelint-disable declaration-no-important --
 iOS will automatically zoom in on form elements when they receive focus, if the
 font size is less than 16px. In order to override Mantine defaults, we set an
 !important override here.

 !important is necessary because we're trying to override Mantine's styles
 which are applied with classes that have higher specificity than external
 stylesheets.
 */
input,
textarea {
  font-size: 16px !important;
  line-height: 1.5 !important;
}
