/* stylelint-disable csstools/value-no-unknown-custom-properties --
   This file overrides a Mantine component, and therefore is allowed
   to reference CSS primitives directly.
*/

.mantine-Button-root {
  color: var(--color-white-1);

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--color-fill-dark);
    color: var(--color-textTertiary);
  }

  /* A translucent background button for use on dark backgrounds */
  &[data-variant='offsetOnDarkBackground'] {
    background-color: var(--color-white-a20);
    color: var(--color-white-1);
  }

  &[data-variant='textButton'] {
    padding: 0;
    background-color: transparent;
    color: var(--color-textTertiary);

    &:hover,
    :focus-visible & {
      color: var(--color-textSecondary);
    }
  }

  &[data-variant='linkButton'] {
    padding: 0;
    background-color: transparent;
    color: var(--color-brandPrimary);
    &:hover,
    &:focus {
      color: var(--color-textSecondary);
    }
  }

  &[data-variant='redButton'] {
    padding: 0;
    background-color: var(--color-errorDark);
  }

  &[data-variant='outline'] {
    background-color: var(--color-background-white);
    color: var(--color-textSecondary);
    border-color: var(--color-border);
    border-width: 1px;

    &:hover,
    &:focus {
      background-color: var(--color-fill-dark);
      color: var(--color-textSecondary);
    }

    .mantine-Button-inner {
      color: var(--color-textSecondary);
    }
  }

  &[data-variant='midGray'] {
    background-color: var(--mantine-color-gray-0);
    color: var(--color-textPrimary);

    &:hover,
    &:focus {
      /* background-color: #c8c8ca; */
      background-color: var(--color-border);
      color: var(--color-textPrimary);
    }
  }
}

/* stylelint-disable-next-line no-duplicate-selectors --
  * Legacy Button component overrides — these should have been implemented as
  * variants on the Button component itself, but were instead implemented as
  * separate classes. They're consolidated into this override file for now.
 */
.mantine-Button-root {
  &.secondaryButton {
    background-color: transparent;
    color: var(--color-brandPrimary);

    &:hover,
    &:focus-visible {
      background-color: transparent;
    }
  }
}
