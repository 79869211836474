/* Shrink label font-size */
.mantine-InputWrapper-label {
  color: var(--color-textTertiary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 4px;
}

.mantine-InputWrapper-error {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties -- applying a framework-level override */
  color: var(--mantine-color-red-8);

  & a {
    color: inherit;
    text-decoration: underline;
  }
}
