.mantine-Badge-root {
  &[data-variant='gray'] {
    background-color: var(--color-fill-dark);
    color: var(--color-textSecondary);
  }

  &[data-variant='success'] {
    background-color: var(--color-successLight);
    color: var(--color-successDark);
  }

  &[data-variant='warning'],
  &[data-variant='medium'] {
    background-color: var(--color-warningLight);
    color: var(--color-warningDark);
  }

  &[data-variant='error'],
  &[data-variant='high'] {
    background-color: var(--color-errorLight);
    color: var(--color-errorDark);
  }

  &[data-variant='low'] {
    background-color: var(--color-lowLight);
    color: var(--color-lowDark);
  }

  &[data-variant='info'] {
    background-color: var(--color-infoLight);
    color: var(--color-infoDark);
  }
}
