.root {
  /* Default: meaning=neutral */
  --backgroundColor: var(--mantine-color-blue-0);
  --borderColor: var(--mantine-color-blue-8);
  --headingColor: var(--mantine-color-blue-8);
  --dismissColor: var(--mantine-color-blue-8);

  background: var(--backgroundColor);

  border: 1px solid var(--borderColor);
  border-radius: var(--radius-sm);
  padding: 10px 12px;

  display: flex;
  flex-direction: row;
  gap: 8px;

  &[data-meaning='positive'],
  &[data-meaning='active'] {
    --backgroundColor: var(--mantine-color-green-0);
    --borderColor: var(--mantine-color-green-8);
    --headingColor: var(--mantine-color-green-8);
    --dismissColor: var(--mantine-color-green-8);
  }

  &[data-meaning='negative'] {
    --backgroundColor: var(--mantine-color-red-0);
    --borderColor: var(--mantine-color-red-8);
    --headingColor: var(--mantine-color-red-8);
    --dismissColor: var(--mantine-color-red-8);
  }

  &[data-meaning='warning'] {
    --backgroundColor: var(--mantine-color-orange-0);
    --borderColor: var(--mantine-color-orange-8);
    --headingColor: var(--mantine-color-orange-8);
    --dismissColor: var(--mantine-color-orange-8);
  }

  &[data-meaning='info'] {
    --backgroundColor: var(--mantine-color-pink-0);
    --borderColor: var(--mantine-color-pink-8);
    --headingColor: var(--mantine-color-pink-8);
    --dismissColor: var(--mantine-color-pink-8);
  }

  &[data-meaning='inactive'] {
    --backgroundColor: var(--mantine-color-gray-0);
    --borderColor: var(--mantine-color-gray-8);
    --headingColor: var(--mantine-color-gray-8);
    --dismissColor: var(--mantine-color-gray-8);
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--headingColor);
  flex-grow: 1;
}

.message {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-textSecondary);
}

.details {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-textSecondary);
}

.dismiss {
  /* Adjust into negative space to align icon, while outline renders */
  position: relative;
  top: -4px;
  margin-left: auto;
}
