/**
 * Color primitives are not automatically allowed to be used in components; instead they are
 * generally used in system variables (system.css), or system components (e.g. Button, Icon)
 * which will declare a lint exception.
 */

:root {
  --color-black: #1e2226;
  --color-white-0: #fefefe;
  --color-white-1: #fcfcfc;

  /* Color primitives. We define these using the Mantine variable palette so they
   * cascade into Mantine components by default. */
  --mantine-color-blue-0: #f7fcff;
  --mantine-color-blue-1: #e8f5ff;
  --mantine-color-blue-2: #cceaff;
  --mantine-color-blue-3: #a4d8fc;
  --mantine-color-blue-4: #75c3fa;
  --mantine-color-blue-5: #40abf7;
  --mantine-color-blue-6: #0f94f2;
  --mantine-color-blue-7: #0781e5;
  --mantine-color-blue-8: #066ccc;
  --mantine-color-blue-9: #0358ad;

  --mantine-color-gray-0: #f7f9fa;
  --mantine-color-gray-1: #f2f3f5;
  --mantine-color-gray-2: #e3e5e8;
  --mantine-color-gray-3: #ced2d6;
  --mantine-color-gray-4: #b6bcc2;
  --mantine-color-gray-5: #a1a6ab;
  --mantine-color-gray-6: #8c9196;
  --mantine-color-gray-7: #7c8187;
  --mantine-color-gray-8: #686e73;
  --mantine-color-gray-9: #4f5459;

  --mantine-color-green-0: #f7fcf9;
  --mantine-color-green-1: #e9faef;
  --mantine-color-green-2: #c5f0d3;
  --mantine-color-green-3: #91e3ac;
  --mantine-color-green-4: #5ed184;
  --mantine-color-green-5: #32ba5f;
  --mantine-color-green-6: #12a643;
  --mantine-color-green-7: #0a9438;
  --mantine-color-green-8: #057d2d;
  --mantine-color-green-9: #006723;

  --mantine-color-orange-0: #fffcf7;
  --mantine-color-orange-1: #fff5e8;
  --mantine-color-orange-2: #ffe1b8;
  --mantine-color-orange-3: #ffc87a;
  --mantine-color-orange-4: #ffa930;
  --mantine-color-orange-5: #fa8611;
  --mantine-color-orange-6: #ed6809;
  --mantine-color-orange-7: #db530f;
  --mantine-color-orange-8: #c43708;
  --mantine-color-orange-9: #a62b02;

  --mantine-color-pink-0: #fdfafc;
  --mantine-color-pink-1: #fbf3f8;
  --mantine-color-pink-2: #f6e0ee;
  --mantine-color-pink-3: #efc5e0;
  --mantine-color-pink-4: #e6a7d1;
  --mantine-color-pink-5: #e484c3;
  --mantine-color-pink-6: #e060b4;
  --mantine-color-pink-7: #d149a1;
  --mantine-color-pink-8: #b9378a;
  --mantine-color-pink-9: #9a2c74;

  --mantine-color-red-0: #fffafa;
  --mantine-color-red-1: #fff0f1;
  --mantine-color-red-2: #ffdedf;
  --mantine-color-red-3: #ffc2c4;
  --mantine-color-red-4: #ffa1a4;
  --mantine-color-red-5: #ff7a7f;
  --mantine-color-red-6: #ff4d53;
  --mantine-color-red-7: #eb343a;
  --mantine-color-red-8: #cf1d23;
  --mantine-color-red-9: #ab1116;

  --mantine-color-yellow-0: #fffce0;
  --mantine-color-yellow-1: #fff6a3;
  --mantine-color-yellow-2: #ffe666;
  --mantine-color-yellow-3: #ffca1c;
  --mantine-color-yellow-4: #f7ad00;
  --mantine-color-yellow-5: #e59100;
  --mantine-color-yellow-6: #d67900;
  --mantine-color-yellow-7: #c96400;
  --mantine-color-yellow-8: #ab5500;
  --mantine-color-yellow-9: #8c4600;

  /** The following Mantine colors are removed (see `/types/mantine.d.ts`)
  *
  * grape, violet, indigo, cyan, teal, lime, dark
  */
}
