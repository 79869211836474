.mantine-Drawer-root {
  /*  These overrides ensure that the drawer will position its width: 100%
   overlays relative to the top-left of the viewport, even when drawer markup
   is kept within the content flow with `withinPortal=false`.

   Otherwise, padding and margin on the containing elements will cause the
   drawer to be positioned offscreen. */
  position: absolute;
  top: 0;
  left: 0;
}
