.root {
  /* Default size: md */
  --size: 24px;

  display: inline-block;
  box-sizing: border-box;
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  min-height: var(--size);

  /* inherit text colour by default; allows customization */
  color: inherit;

  /* Setting vertical-align prevents additional space from being added
   * below the icon when it is used within an <a> link element. */
  vertical-align: top;

  & svg {
    display: block;
    width: 100%;
    height: 100%;
    color: inherit;
  }

  &[data-size='xs'] {
    --size: 16px;
  }

  &[data-size='sm'] {
    --size: 20px;
  }

  &[data-size='lg'] {
    --size: 36px;
  }

  &[data-size='xl'] {
    --size: 64px;
  }

  &[data-size='xxl'] {
    --size: 84px;
  }

  &[data-meaning='primary'] {
    color: var(--color-brandPrimary);
  }

  &[data-meaning='accent'] {
    color: var(--color-brandAccent);
  }

  &[data-meaning='neutral'],
  &[data-meaning='inactive'] {
    color: var(--mantine-color-gray-7);
  }

  &[data-meaning='positive'] {
    color: var(--mantine-color-green-8);
  }

  &[data-meaning='active'] {
    color: var(--color-brandPrimary-active);
  }

  &[data-meaning='negative'],
  &[data-meaning='high'] {
    color: var(--mantine-color-red-8);
  }

  &[data-meaning='warning'],
  &[data-meaning='medium'] {
    color: var(--mantine-color-orange-8);
  }

  &[data-meaning='default'],
  &[data-meaning='low'] {
    color: var(--mantine-color-blue-8);
  }

  &[data-meaning='info'] {
    color: var(--mantine-color-pink-9);
  }

  &[data-variant='enclosed'] {
    --relativePadding: 0.12;
    border-radius: var(--radius-xl);
    /* need to use the rendered stroke width for  border width:
     * Icon sources have stroke width of 2px in 24px viewBox.
     * Therefore, actual stroke is: (size - padding) * (2 / 24)
     */
    border: currentColor solid
      calc(
        (var(--size) - (var(--size) * (2 * var(--relativePadding)))) * (2 / 24)
      );
    padding: calc(var(--size) * var(--relativePadding));
  }

  /* Filled variant is a white icon on coloured background.
     Background colors are a different shade from the stroke variants. */
  &[data-variant='filled'] {
    --relativePadding: 0.2;
    border-radius: var(--radius-xl);
    background-color: currentColor;
    padding: calc(var(--size) * var(--relativePadding));

    & svg {
      color: var(--color-white-1);
    }

    &[data-meaning='neutral'],
    &[data-meaning='inactive'] {
      color: var(--mantine-color-gray-6);
    }

    &[data-meaning='active'],
    &[data-meaning='positive'] {
      color: var(--mantine-color-green-6);
    }

    &[data-meaning='negative'],
    &[data-meaning='high'] {
      color: var(--mantine-color-red-7);
    }

    &[data-meaning='warning'],
    &[data-meaning='medium'] {
      color: var(--mantine-color-orange-6);
    }

    &[data-meaning='neutral'],
    &[data-meaning='low'] {
      color: var(--mantine-color-blue-6);
    }

    &[data-meaning='info'] {
      color: var(--mantine-color-pink-7);
    }
  }

  &[data-variant='two-tone'] {
    --relativePadding: 0.285;
    border-radius: var(--radius-xl);
    padding: calc(var(--size) * var(--relativePadding));

    &[data-meaning='primary'] {
      color: var(--color-brandPrimary);
      background-color: var(--color-brandPrimary-subtle);
    }

    &[data-meaning='accent'] {
      color: var(--color-brandAccent);
      background-color: var(--color-background-dark);
    }

    &[data-meaning='neutral'],
    &[data-meaning='inactive'] {
      color: var(--mantine-color-gray-7);
      background-color: var(--mantine-color-gray-1);
    }

    &[data-meaning='positive'] {
      color: var(--mantine-color-green-8);
      background-color: var(--mantine-color-green-1);
    }

    &[data-meaning='active'] {
      color: var(--color-brandPrimary-active);
      background-color: var(--color-brandPrimary-subtle);
    }

    &[data-meaning='negative'],
    &[data-meaning='high'] {
      color: var(--mantine-color-red-8);
      background-color: var(--mantine-color-red-1);
    }

    &[data-meaning='warning'],
    &[data-meaning='medium'] {
      color: var(--mantine-color-orange-8);
      background-color: var(--mantine-color-orange-1);
    }

    &[data-meaning='default'],
    &[data-meaning='low'] {
      color: var(--mantine-color-blue-8);
      background-color: var(--mantine-color-blue-1);
    }

    &[data-meaning='info'] {
      color: var(--mantine-color-pink-9);
      background-color: var(--mantine-color-pink-1);
    }
  }

  /* When marked as interactive, the icon will adopt currentColor from
     the parent when that parent is in an interactive state */
  &[data-interactive='true'] {
    :hover &,
    :focus-visible & {
      color: currentColor;
    }
  }
}
