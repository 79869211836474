.root {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 100vw;
  min-height: 80vh;
  padding: 24px;
  background-color: var(--color-background-white);
}

.message {
  font-size: 14px;
  font-weight: 400;
}
