/* stylelint-disable csstools/value-no-unknown-custom-properties --
   This file is declaring Mantine system variables, and therefore is allowed to use CSS primitives.
*/

/* This file declares a subset of the Mantine v7 global custom properties
 * needed for Mantine components to render. We manage them here in CSS so
 * as to ensure style configuration is consistently managed, and to avoid
 * Mantine injecting so much un-minified CSS into the page body.
 *
 * We remove styles for Mantine features that we don't use (e.g. typography)
 * to keep the file as small as possible. We also don't declare light/dark variations,
 * as our applications today are single-theme.
 */

.theme-root {
  /* Mantine configuration */
  --mantine-scale: 1;
  --mantine-cursor-type: default;
  --mantine-color-scheme: light;

  /* Mantine layout */
  /* Breakpoint vars injected from postcss */
  --mantine-breakpoint-xs: $mantine-breakpoint-xs;
  --mantine-breakpoint-sm: $mantine-breakpoint-sm;
  --mantine-breakpoint-md: $mantine-breakpoint-md;
  --mantine-breakpoint-lg: $mantine-breakpoint-lg;
  --mantine-breakpoint-xl: $mantine-breakpoint-xl;

  --mantine-spacing-xs: 0.625rem;
  --mantine-spacing-sm: 0.75rem;
  --mantine-spacing-md: 1rem;
  --mantine-spacing-lg: 1.25rem;
  --mantine-spacing-xl: 2rem;

  --mantine-radius-xs: var(--radius-xs);
  --mantine-radius-sm: var(--radius-sm);
  --mantine-radius-md: var(--radius-md);
  --mantine-radius-lg: var(--radius-lg);
  --mantine-radius-xl: var(--radius-xl);
  --mantine-radius-default: var(--radius-sm);

  --mantine-shadow-xs: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1);
  --mantine-shadow-sm: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 0.625rem 0.9375rem -0.3125rem,
    rgba(0, 0, 0, 0.04) 0 0.4375rem 0.4375rem -0.3125rem;
  --mantine-shadow-md: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 1.25rem 1.5625rem -0.3125rem,
    rgba(0, 0, 0, 0.04) 0 0.625rem 0.625rem -0.3125rem;
  --mantine-shadow-lg: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 1.75rem 1.4375rem -0.4375rem,
    rgba(0, 0, 0, 0.04) 0 0.75rem 0.75rem -0.4375rem;
  --mantine-shadow-xl: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 2.25rem 1.75rem -0.4375rem,
    rgba(0, 0, 0, 0.04) 0 1.0625rem 1.0625rem -0.4375rem;

  /* Mantine typography */
  --mantine-webkit-font-smoothing: antialiased;
  --mantine-moz-font-smoothing: grayscale;
  --mantine-line-height: 1.55;
  --mantine-font-family: var(--fontFamily);
  --mantine-font-family-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco,
    Consolas, Liberation Mono, Courier New, monospace;

  --mantine-font-size-xs: 0.75rem;
  --mantine-font-size-sm: 0.875rem;
  --mantine-font-size-md: 1rem;
  --mantine-font-size-lg: 1.125rem;
  --mantine-font-size-xl: 1.25rem;
  --mantine-line-height-xs: 1.4;
  --mantine-line-height-sm: 1.45;
  --mantine-line-height-md: 1.55;
  --mantine-line-height-lg: 1.6;
  --mantine-line-height-xl: 1.65;

  /* Mantine applied colors */

  --mantine-color-white: var(--color-white-1);
  --mantine-color-black: var(--color-black);

  /* We must declare named 'brand' and 'primary' colors as
   * gradients, as some Mantine components generate these variable
   * names internally. */
  --mantine-color-brand-0: var(--color-brandPrimary);
  --mantine-color-brand-1: var(--color-brandPrimary);
  --mantine-color-brand-2: var(--color-brandPrimary);
  --mantine-color-brand-3: var(--color-brandPrimary);
  --mantine-color-brand-4: var(--color-brandPrimary);
  --mantine-color-brand-5: var(--color-brandPrimary);
  --mantine-color-brand-6: var(--color-brandPrimary);
  --mantine-color-brand-7: var(--color-brandPrimary);
  --mantine-color-brand-8: var(--color-brandPrimary);
  --mantine-color-brand-9: var(--color-brandPrimary);

  --mantine-primary-color-0: var(--color-brandPrimary);
  --mantine-primary-color-1: var(--color-brandPrimary);
  --mantine-primary-color-2: var(--color-brandPrimary);
  --mantine-primary-color-3: var(--color-brandPrimary);
  --mantine-primary-color-4: var(--color-brandPrimary);
  --mantine-primary-color-5: var(--color-brandPrimary);
  --mantine-primary-color-6: var(--color-brandPrimary);
  --mantine-primary-color-7: var(--color-brandPrimary);
  --mantine-primary-color-8: var(--color-brandPrimary);
  --mantine-primary-color-9: var(--color-brandPrimary);

  --mantine-color-text: var(--color-black);
  --mantine-color-body: var(--color-white-1);
  --mantine-color-bright: var(--color-black);
  --mantine-color-error: var(--color-errorDark);
  --mantine-color-placeholder: var(--color-textTertiary);
  --mantine-color-anchor: var(--color-brandPrimary);
  --mantine-color-default: var(--color-white-1);
  --mantine-color-default-hover: var(--color-fill-dark);
  --mantine-color-default-color: var(--color-black);
  --mantine-color-default-border: var(--color-border);

  --mantine-color-gray-text: var(--mantine-color-gray-filled);
  --mantine-color-gray-filled: var(--mantine-color-gray-6);
  --mantine-color-gray-filled-hover: var(--mantine-color-gray-7);
  --mantine-color-gray-light: rgba(134, 142, 150, 0.1);
  --mantine-color-gray-light-hover: rgba(134, 142, 150, 0.12);
  --mantine-color-gray-light-color: var(--mantine-color-gray-6);
  --mantine-color-gray-outline: var(--mantine-color-gray-6);
  --mantine-color-gray-outline-hover: rgba(134, 142, 150, 0.05);

  --mantine-color-red-text: var(--mantine-color-red-filled);
  --mantine-color-red-filled: var(--mantine-color-red-6);
  --mantine-color-red-filled-hover: var(--mantine-color-red-7);
  --mantine-color-red-light: rgba(250, 82, 82, 0.1);
  --mantine-color-red-light-hover: rgba(250, 82, 82, 0.12);
  --mantine-color-red-light-color: var(--mantine-color-red-6);
  --mantine-color-red-outline: var(--mantine-color-red-6);
  --mantine-color-red-outline-hover: rgba(250, 82, 82, 0.05);

  --mantine-color-orange-text: var(--mantine-color-orange-filled);
  --mantine-color-orange-filled: var(--mantine-color-orange-6);
  --mantine-color-orange-filled-hover: var(--mantine-color-orange-7);
  --mantine-color-orange-light: rgba(253, 126, 20, 0.1);
  --mantine-color-orange-light-hover: rgba(253, 126, 20, 0.12);
  --mantine-color-orange-light-color: var(--mantine-color-orange-6);
  --mantine-color-orange-outline: var(--mantine-color-orange-6);
  --mantine-color-orange-outline-hover: rgba(253, 126, 20, 0.05);

  --mantine-color-yellow-text: var(--mantine-color-yellow-filled);
  --mantine-color-yellow-filled: var(--mantine-color-yellow-6);
  --mantine-color-yellow-filled-hover: var(--mantine-color-yellow-7);
  --mantine-color-yellow-light: rgba(250, 176, 5, 0.1);
  --mantine-color-yellow-light-hover: rgba(250, 176, 5, 0.12);
  --mantine-color-yellow-light-color: var(--mantine-color-yellow-6);
  --mantine-color-yellow-outline: var(--mantine-color-yellow-6);
  --mantine-color-yellow-outline-hover: rgba(250, 176, 5, 0.05);

  --mantine-color-green-text: var(--mantine-color-green-filled);
  --mantine-color-green-filled: var(--mantine-color-green-6);
  --mantine-color-green-filled-hover: var(--mantine-color-green-7);
  --mantine-color-green-light: rgba(64, 192, 87, 0.1);
  --mantine-color-green-light-hover: rgba(64, 192, 87, 0.12);
  --mantine-color-green-light-color: var(--mantine-color-green-6);
  --mantine-color-green-outline: var(--mantine-color-green-6);
  --mantine-color-green-outline-hover: rgba(64, 192, 87, 0.05);

  --mantine-color-blue-text: var(--mantine-color-blue-filled);
  --mantine-color-blue-filled: var(--mantine-color-blue-6);
  --mantine-color-blue-filled-hover: var(--mantine-color-blue-7);
  --mantine-color-blue-light: rgba(34, 139, 230, 0.1);
  --mantine-color-blue-light-hover: rgba(34, 139, 230, 0.12);
  --mantine-color-blue-light-color: var(--mantine-color-blue-6);
  --mantine-color-blue-outline: var(--mantine-color-blue-6);
  --mantine-color-blue-outline-hover: rgba(34, 139, 230, 0.05);

  --mantine-color-pink-text: var(--mantine-color-pink-filled);
  --mantine-color-pink-filled: var(--mantine-color-pink-6);
  --mantine-color-pink-filled-hover: var(--mantine-color-pink-7);
  --mantine-color-pink-light: rgba(230, 73, 128, 0.1);
  --mantine-color-pink-light-hover: rgba(230, 73, 128, 0.12);
  --mantine-color-pink-light-color: var(--mantine-color-pink-6);
  --mantine-color-pink-outline: var(--mantine-color-pink-6);
  --mantine-color-pink-outline-hover: rgba(230, 73, 128, 0.05);

  --mantine-primary-color-filled: var(--color-brandPrimary);
  --mantine-primary-color-filled-hover: var(--color-brandPrimary-active);
  --mantine-primary-color-light: var(--color-brandPrimary-light);
  --mantine-primary-color-light-hover: var(--color-brandPrimary-subtle);
  --mantine-primary-color-light-color: var(--color-brandPrimary-light);
  --mantine-primary-color-contrast: var(--color-white-1);

  --mantine-color-brand-filled: var(--mantine-primary-color-filled);
  --mantine-color-brand-filled-hover: var(--mantine-primary-color-filled-hover);
  --mantine-color-brand-light: var(--mantine-primary-color-light);
  --mantine-color-brand-light-hover: var(--mantine-primary-color-light-hover);
  --mantine-color-brand-light-color: var(--mantine-primary-color-light-color);
  --mantine-color-brand-contrast: var(--mantine-primary-color-contrast);

  --tooltip-bg: var(--color-black);
  --tooltip-color: var(--color-white-1);
}
