.root {
  /* We declare inline-block so it applies to an empty DIV placeholder as well */
  display: inline-block;

  /* We set a default 24x24 width, matching the default viewBox size of
  * our SVG assets, but will generally expect this to get overridden by
  * icon components. */
  width: 24px;
  height: 24px;

  stroke-width: 1.5px;
  stroke-linecap: round;
  stroke-linejoin: round;

  /* We expect SVG sources to include `stroke` and `fill` attributes with `currentColor` as
   * appropriate. `currentColor` inherits `color` when embedded in HTML.
   *
   * The default style is usually:
   *
   * stroke: currentColor;
   * fill: none;
   */
}
