.button {
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  color: var(--mantine-color-gray-8);
  display: flex;
  height: var(--size);
  justify-content: center;
  /* set min/max to prevent round button being stretched by flex containers */
  max-width: var(--size);
  min-width: var(--size);

  --size: 36px;

  &[data-size='sm'] {
    --size: 32px;
  }

  &[data-size='md'] {
    --size: 36px;
  }

  &[data-size='lg'] {
    --size: 40px;
  }

  &:hover:enabled,
  &:focus-visible:enabled {
    background-color: var(--color-fill-dark);
    color: var(--mantine-color-gray-9);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}
