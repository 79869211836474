/* stylelint-disable csstools/value-no-unknown-custom-properties --
   This file is declaring system variables, and therefore is allowed to use CSS primitives.
*/

:root {
  /* Legacy: Component Colors; refactor into modules */
  --color-videoButtonGreen: #14ae5c;
  --color-schedulerActiveEventCardColor: #e1f9e6;
  --color-schedulerActiveEventBadgeColor: #14ae5c;
  --color-schedulerInactiveEventCardColor: #f3f3f3;
  --color-schedulerInactiveEventBadgeColor: #a8a8a8;

  /* Legacy: Semantic variant colors; refactor into new component
   * modules as exact shade will vary by size/weight of usage */
  --color-errorDark: var(--mantine-color-red-8);
  --color-errorLight: var(--mantine-color-red-0);
  --color-warningDark: var(--mantine-color-orange-8);
  --color-warningLight: var(--mantine-color-orange-0);
  --color-successDark: var(--mantine-color-green-8);
  --color-successLight: var(--mantine-color-green-0);
  --color-infoDark: var(--mantine-color-pink-9);
  --color-infoLight: var(--mantine-color-pink-0);
  --color-lowDark: var(--mantine-color-blue-8);
  --color-lowLight: var(--mantine-color-blue-0);

  --color-notificationDot: var(--mantine-color-red-6);
  --color-notificationDotMedium: var(--mantine-color-orange-4);
  --color-notificationDotLow: var(--mantine-color-blue-6);

  /** Future, not implemented yet: Standard stroke sizes.
   *  --stroke-xs: 0.5px;
   *  --stroke-sm: 1px;
   *  --stroke-md: 1.5px;
   *  --stroke-lg: 2px;
   *  --stroke-xl: 3px;
   *  --stroke-xxl: 4px;
   */

  /**
   * Base Application Theme
   */
  --color-textPrimary: var(--color-black);
  --color-textSecondary: var(--mantine-color-gray-9);
  --color-textTertiary: var(--mantine-color-gray-8);
  --color-textPlaceholder: var(--mantine-color-gray-6);
  --color-textDisabled: var(--mantine-color-gray-5);
  --color-textInverse: var(--color-white-1);

  --color-textHighlightBackground: var(--mantine-color-yellow-2);

  --color-link: var(--color-brandPrimary);
  --color-link-active: var(--color-brandPrimary-active);

  --color-fill-white: var(--color-white-0);
  --color-fill-light: var(--color-white-1);
  --color-fill-mid: var(--mantine-color-gray-0);
  --color-fill-dark: var(--mantine-color-gray-1);
  --color-fill-heavy: var(--mantine-color-gray-2);
  --color-fill-black: var(--color-black);

  --color-border: var(--mantine-color-gray-3);
  --color-border-dark: var(--mantine-color-gray-5);
  --color-divider: var(--mantine-color-gray-2);

  --color-focusRing: var(--mantine-color-blue-8);

  /* This var gets set by next/font, so we define a default here for autocomplete */
  --fontFamily: inherit;
}

.theme-root {
  font: 400 16px/1.5 var(--fontFamily), sans-serif;
  font-family: var(--fontFamily);
  background-color: var(--color-background-dark);
  color: var(--color-textPrimary);
  min-height: 100vh;

  @media print {
    background-color: #ffffff;
  }
}
