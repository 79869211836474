/* stylelint-disable csstools/value-no-unknown-custom-properties --
   This file overrides a Mantine component, and therefore is allowed
   to reference CSS primitives directly.
*/

.mantine-Container-root {
  /* A container with our standard page padding for non-fullbleed UI indentation */
  &[data-variant='applicationPadding'] {
    padding: 20px;
  }

  /* A container with dark brand background and light text */
  &[data-variant='darkContainer'] {
    background-color: var(--color-brandPrimary);
    color: var(--color-white-1);
  }
}
