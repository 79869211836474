.survey-js-form-wrapper {
  background-color: var(--color-background-white);
  /* Layout: start */
  .sd-root-modern {
    background-color: inherit;

    .sd-body.sd-body--static {
      padding: 0;
    }

    .sd-body .sd-body__page {
      min-width: unset;
      padding: 0;

      .sd-row {
        /* This div doesn't have a class nor property to target with thats why this selector was used */
        & > div {
          /* stylelint-disable-next-line declaration-no-important -- needed to override the inline style */
          min-width: inherit !important;
        }

        .sd-element--with-frame.sd-element--compact {
          border: none;
          padding: 0;

          .sd-panel__header.sd-element__header.sd-element__header--location-top {
            /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties --
             * Allowing unknown variable from SurveyJS as part of legacy implementation */
            border-bottom: 1px solid var(--sjs-border-light);

            &::after {
              content: '';
              display: none;
            }
          }
        }
      }
    }

    .sd-body.sd-completedpage {
      padding: 24px 0;

      &::before {
        height: 0;
      }
    }
  }
  /* Layout: end */

  /* Title: start */
  .sv-title-actions__title {
    display: flex;
  }

  .sd-question__header {
    margin-bottom: 1.5rem;
    padding: 0;

    .sd-element__title {
      .sv-string-viewer {
        /* stylelint-disable-next-line declaration-no-important -- needed to override the inline style */
        display: block !important;
        font-size: 1.25rem;
        font-weight: 700;
        letter-spacing: 0.2px;
        line-height: 1.5rem;
        color: var(--color-textPrimary);
      }

      .sd-element__num {
        display: none;
      }

      [data-key='num-sp'] {
        display: none;
      }

      [data-key='req-text'] {
        display: none;
      }
    }

    .sd-description {
      color: var(--color-textSecondary);
    }
  }
  /* Title: end */

  /* Buttons: start */
  .sd-body.sd-body--static {
    .sd-body__navigation {
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;
      margin-top: 1.5rem;
      padding: 0;

      .sv-action {
        width: 100%;

        .sd-btn {
          border-radius: 3.125rem;
          box-shadow: none;
          font-size: 1rem;
          font-weight: 600;
          height: 3rem;
          line-height: 1rem;
          width: 100%;
        }

        .sd-navigation__next-btn,
        .sd-navigation__complete-btn {
          /* stylelint-disable-next-line declaration-no-important -- needed to override the inline style */
          background-color: var(--color-brandPrimary) !important;
          color: var(--color-background-white);
          outline-color: var(--color-brandPrimary);
          outline-width: 1px;

          &:focus {
            outline: 2px solid var(--color-brandPrimary);
            outline-offset: 2px;
          }
        }

        .sd-navigation__prev-btn {
          border: none;
          color: var(--color-textPrimary);

          &:focus {
            outline: 2px solid var(--color-textPrimary);
            outline-offset: -3px;
          }
        }
      }
      &.sd-action-bar {
        padding: 0;
      }
    }
  }

  .sd-success_back_home_btn {
    background-color: transparent;
    border-radius: 3.125rem;
    color: var(--color-textSecondary);
    outline-color: var(--color-border);
    outline-width: 1px;
    padding: 10px 24px;
    text-decoration: none;
    width: fit-content;
  }
  /* Buttons: end */

  /* Question types : start  */
  .sd-question__content {
    .sd-selectbase {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .sd-radio,
    .sd-checkbox {
      background: var(--color-background-white);
      border-radius: 0.625rem;
      border: 1px solid var(--color-border);
      padding: 1rem;

      &:focus-within {
        border: 2px solid var(--color-brandPrimary);
      }
    }
    .sd-selectbase__label {
      align-items: center;
      display: flex;
      gap: 0.75rem;
    }
    .sd-item__control-label {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.25rem;
    }
    .sd-radio__decorator,
    .sd-checkbox__decorator {
      border: 2px solid var(--color-textSecondary);
      box-shadow: unset;
      height: 1.275rem;
      width: 1.275rem;
    }
    .sd-item--checked .sd-item__decorator {
      border: none;
    }
    .sd-item__control:focus + .sd-item__decorator {
      border: 2px solid var(--color-brandPrimary);
      box-shadow: unset;
    }
    .sd-radio--checked,
    .sd-checkbox--checked {
      border: 2px solid var(--color-brandPrimary);
    }
    .sd-input {
      font-size: 0.875rem;
      background-color: var(--color-background-white);
    }
  }
  .sd-text__content {
    padding: 0 1px;
  }
  /* Question types : end  */
}

.survey-js-success-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .success-icon-container {
    align-items: center;
    /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties --
     This color is an aesthetic design choice direct from our color primitives.
    */
    background-color: var(--mantine-color-green-1);
    border-radius: 50%;
    display: flex;
    height: 84px;
    justify-content: center;
    width: 84px;
  }

  .title {
    color: var(--color-textPrimary);
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-top: 16px;
    text-align: center;
  }

  .description {
    color: var(--color-textSecondary);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
  }
}
